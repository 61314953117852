//this is Mr. Muthukumaran Azhagesan's Sdvjsdfjkasdhjhjh.js 
//Author: Mr. Muthukumaran Azhagesan, Proven Software Architect, Developer, SAP Consultant, iOS app developer, Inventor, and some more.
//check https://www.damnittameitai.co for his AI/ML projects, all interesting ones.
//check https://www.autoshields.website for Muthukumaran's useful and life saving inventions{all PATENTED}.

import React, { useState, useRef, useEffect } from "react";
import PlayerDetails from "./PlayerDetails";
import PlayerControls from "./PlayerControls";
import SpectrumAnalyzer from './SpectrumAnalyzer';
import AtroposImage from "./AtroposImage.js"; 
import PowerSlider from './PowerSlider.js';
import WaterDroplet from "./WaterDroplet.js"; 
import ConvexGlassCanvas from "./ConvexGlassCanvas.js"; 

function sdflhsfkh() {
  const sfsfssfsdfs = navigator.userAgent.toLowerCase();
  const dfjksdfkj = [
    "android",
    "webos",
    "iphone",
    "ipad",
    "ipod",
    "blackberry",
    "windows phone",
  ];
  return dfjksdfkj.some((keyword) => sfsfssfsdfs.includes(keyword));
}

function Sdvjsdfjkasdhjhjh(props) {
  const ksjhkgakETUUIUI = useRef(null);
  const [mcnVmcn, setIsPlaying] = useState(false);
  const [likes, setLikes] = useState(props.songs.map(() => 100));
  const [tdusfsfsdfTud] = useState(true);
  const nbzxcnzbSfh = useRef(null);
  const nbzxcnzbSfh2 = useRef(null);
  const nbzxcnzbSfh3 = useRef(null);
  const mmnnSfh = useRef(null);
  const nbxnTgi = useRef(null);
  const nbxnTgi2 = useRef(null);
  const nbxnTgi3 = useRef(null);
  const zzmnmnzxmnzmxnzQde = useRef(new (window.AudioContext || window.webkitAudioContext)()); //audioContextRef
  const laslkallkllasQde = useRef(zzmnmnzxmnzmxnzQde.current.createAnalyser());
  const laslkallkllasTgi = useRef(null);
  const [oiuytyui, swdPnkeij] = useState(0);
  var isSkipSong = true;
  const [mnczbxncb, mnczbxncb2] = useState(null);
  const [xvczvc, xvczvc2] = useState(null);
  const [erqrttwe, erqrttwe2] = useState(null); 
  const options = [
    { text: 'Red', value: 1, color: 'red', red: 255, green: 0, blue: 0 },
    { text: 'Green', value: 3, color: 'green', red: 124, green: 252, blue: 0 },
    { text: 'Blue', value: 2, color: 'blue', red: 0, green: 0, blue: 205 },
    { text: 'Yellow', value: 4, color: 'yellow', red: 255, green: 217, blue: 5   }
  ];
  const [selectedValue, setSelectedValue] = useState(null);
  const [rvrxrrxeeGwzxw, zxqzqUymym] = useState(false);
  const [rvrxrrxeeGwzxw2, zxqzqUymym2] = useState(false); 
  const {pauseIt, setPauseIt} = useState(false);  
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [fftSize, setFftSize] = useState(32);
  const [isCanvasVisible, setIsCanvasVisible] = useState(false);

  const handleFFTChange = (newValue) => {
    setFftSize(newValue);
  };

  useEffect(() => {
    const audioRefNow = mmnnSfh.current;
      if (audioRefNow) {
          const updateTime = () => setCurrentTime(mmnnSfh.currentTime);
          const updateDuration = () => setDuration(audioRefNow.duration);
          audioRefNow.addEventListener('timeupdate', updateTime);
          audioRefNow.addEventListener('loadedmetadata', updateDuration);
        return () => {
          audioRefNow.removeEventListener('timeupdate', updateTime);
          audioRefNow.removeEventListener('loadedmetadata', updateDuration);
      };
    }
  }, []);

  const qwerTsdfg = (option)  =>  { 
    setSelectedValue(option);
    mnczbxncb2(option.red);
    xvczvc2(option.green);
    erqrttwe2(option.blue);
    zxqzqUymym(!rvrxrrxeeGwzxw); 
    zxqzqUymym2(!rvrxrrxeeGwzxw2); 
    if (!isCanvasVisible) {
      setIsCanvasVisible(true);
    }
  }

  useEffect(() => {
    const canvas = nbzxcnzbSfh.current;
      if (canvas != null) {
          const mcnxmaqdasdaEvz = canvas.getContext('2d', { willReadFrequently: true });   
          mcnxmaqdasdaEvz.fillStyle = 'transparent';
          mcnxmaqdasdaEvz.fillStyle = 'rgba(255, 255, 255, 255)'; // semi-transparent red
          const mcnxmaqdasdatfs1 = laslkallkllasQde.current;
          
          if(sdflhsfkh) {
              canvas.width = window.innerWidth;
              canvas.height = window.innerHeight/4;
          } else {
              canvas.width = window.innerWidth*.5;
              canvas.height = window.innerHeight*3.75;
          }
          if (mcnxmaqdasdatfs1) {
              mcnxmaqdasdatfs1.fftSize = fftSize;
          }
          const wqpwoqalskalMfo = mcnxmaqdasdatfs1.frequencyBinCount;
          let erzrzyc   = new Uint8Array(wqpwoqalskalMfo);
          mcnxmaqdasdaEvz.clearRect(0, 0, canvas.width, canvas.height);
          const qeuqeqweiqweiowiweioqu = () => {
            if (nbxnTgi) {
              const img = new Image();
              img.src = "{equalizerBgImage}";
              img.onload = () => { 
              mcnxmaqdasdaEvz.fillStyle = 'transparent';
              mcnxmaqdasdaEvz.globalAlpha = 0.9;
              mcnxmaqdasdaEvz.fillRect(10, 10, 200, 200); 
            }; 
            nbxnTgi.current = requestAnimationFrame(qeuqeqweiqweiowiweioqu);
            mcnxmaqdasdatfs1.getByteFrequencyData(erzrzyc);
            mcnxmaqdasdaEvz.fillStyle = 'rgb(0, 0, 0)';
            mcnxmaqdasdaEvz.fillRect(0, 0, canvas.width, canvas.height); 
            mcnxmaqdasdaEvz.globalAlpha = 1.0; // Reset opacity for drawing bars
            const zxmnzxmnk =  (canvas.width / wqpwoqalskalMfo) * 1.45;      
            let aslakslakasasa;
            let x = 0;

            for (let i = 0; i < wqpwoqalskalMfo; i++) {
              aslakslakasasa = erzrzyc[i];
              const iwyeqieyqwyeqouyuyweww = `rgb(${mnczbxncb }, ${xvczvc}, ${erqrttwe})`;
              mcnxmaqdasdaEvz.fillStyle = iwyeqieyqwyeqouyuyweww;
              mcnxmaqdasdaEvz.fillRect(x, canvas.height - aslakslakasasa , zxmnzxmnk, aslakslakasasa+50 );
              x += zxmnzxmnk + 1;
            }      
          }
          };
          qeuqeqweiqweiowiweioqu();
          return () => {
            cancelAnimationFrame(nbxnTgi.current);
          };
        }
  }, [erqrttwe, xvczvc, mnczbxncb, rvrxrrxeeGwzxw, fftSize]);

  useEffect(() => {
    const canvas = nbzxcnzbSfh2.current;
      if (canvas != null) {
          const mcnxmaqdasdaEvz2 = canvas.getContext('2d');   
          mcnxmaqdasdaEvz2.fillStyle = 'transparent';
          const mcnxmaqdasdatfs2 = laslkallkllasQde.current;
          
          if(sdflhsfkh) {
              canvas.width = window.innerWidth;
              canvas.height = window.innerHeight/4;
          } else {
              canvas.width = window.innerWidth*.25;
              canvas.height = window.innerHeight*3.75;
          }
          if (mcnxmaqdasdatfs2) {
              mcnxmaqdasdatfs2.fftSize = fftSize;
		
          }
          const wqpwoqalskalMfo2 = mcnxmaqdasdatfs2.frequencyBinCount;
          let erzrzyc2   = new Uint8Array(wqpwoqalskalMfo2);
          mcnxmaqdasdaEvz2.clearRect(0, 0, canvas.width, canvas.height);
          const qeuqeqweiqweiowiweioqu2 = () => {
            if (nbxnTgi2) {
              const img = new Image();
              img.src = "{equalizerBgImage}";
              img.onload = () => { 
              mcnxmaqdasdaEvz2.fillStyle = 'transparent';
              mcnxmaqdasdaEvz2.fillRect(10, 10, 200, 200); 
            };  
            nbxnTgi2.current = requestAnimationFrame(qeuqeqweiqweiowiweioqu2);
            mcnxmaqdasdatfs2.getByteFrequencyData(erzrzyc2);
            mcnxmaqdasdaEvz2.fillStyle = 'rgb(0, 0, 0)';
            mcnxmaqdasdaEvz2.fillRect(0, 0, canvas.width, canvas.height);
            const zxmnzxmnk2 =  (canvas.width / wqpwoqalskalMfo2) * 1.45;      
            let aslakslakasasa;
            let x = 0;

            for (let i = 0; i < wqpwoqalskalMfo2; i++) {
              aslakslakasasa = erzrzyc2[i];
              const iwyeqieyqwyeqouyuyweww = `rgb(${mnczbxncb }, ${xvczvc}, ${erqrttwe})`;
              mcnxmaqdasdaEvz2.fillStyle = iwyeqieyqwyeqouyuyweww;
              mcnxmaqdasdaEvz2.fillRect(x, canvas.height - aslakslakasasa , zxmnzxmnk2, aslakslakasasa+50 );
              x += zxmnzxmnk2 + 1;
            }      
          }
          };
          qeuqeqweiqweiowiweioqu2();
          return () => {
            cancelAnimationFrame(nbxnTgi2.current);
          };
        }
  }, [erqrttwe, xvczvc, mnczbxncb, rvrxrrxeeGwzxw, fftSize]);


  useEffect(() => {
    const canvas = nbzxcnzbSfh3.current;
      if (canvas != null) {
          const mcnxmaqdasdaEvz3 = canvas.getContext('2d');   
          mcnxmaqdasdaEvz3.fillStyle = 'transparent';
          const mcnxmaqdasdatfs3 = laslkallkllasQde.current;
          
          if(sdflhsfkh) {
              canvas.width = window.innerWidth;
              canvas.height = window.innerHeight/4;
          } else {
              canvas.width = window.innerWidth*.25;
              canvas.height = window.innerHeight*3.75;
          }
          if (mcnxmaqdasdatfs3) {
              mcnxmaqdasdatfs3.fftSize = fftSize;		
          }
          const wqpwoqalskalMfo3 = mcnxmaqdasdatfs3.frequencyBinCount;
          let erzrzyc3   = new Uint8Array(wqpwoqalskalMfo3);
          mcnxmaqdasdaEvz3.clearRect(0, 0, canvas.width, canvas.height);
          const qeuqeqweiqweiowiweioqu3 = () => {
            if (nbxnTgi3) {
              const img = new Image();
              img.src = "{equalizerBgImage}";
              img.onload = () => { 
              mcnxmaqdasdaEvz3.fillStyle = 'transparent';
              mcnxmaqdasdaEvz3.fillRect(10, 10, 200, 200); 
            }; 
            nbxnTgi3.current = requestAnimationFrame(qeuqeqweiqweiowiweioqu3);
            mcnxmaqdasdatfs3.getByteFrequencyData(erzrzyc3);
            mcnxmaqdasdaEvz3.fillStyle = 'rgb(0, 0, 0)';
            mcnxmaqdasdaEvz3.fillRect(0, 0, canvas.width, canvas.height);
            const zxmnzxmnk3 =  (canvas.width / wqpwoqalskalMfo3) * 1.45;      
            let aslakslakasasa;
            let x = 0;

            for (let i = 0; i < wqpwoqalskalMfo3; i++) {
              aslakslakasasa = erzrzyc3[i];
              const iwyeqieyqwyeqouyuyweww = `rgb(${mnczbxncb }, ${xvczvc}, ${erqrttwe})`;
              mcnxmaqdasdaEvz3.fillStyle = iwyeqieyqwyeqouyuyweww;
              mcnxmaqdasdaEvz3.fillRect(x, canvas.height - aslakslakasasa , zxmnzxmnk3, aslakslakasasa+50 );
              x += zxmnzxmnk3 + 1;
            }      
          }
          };
          qeuqeqweiqweiowiweioqu3();
          return () => {
            cancelAnimationFrame(nbxnTgi3.current);
          };
        }
  }, [erqrttwe, xvczvc, mnczbxncb, rvrxrrxeeGwzxw, fftSize]);

  useEffect(() => {
    if (tdusfsfsdfTud != null && tdusfsfsdfTud.current != null) {
      mppqbcwewrxexWknjan(props.songs[props.currentSongIndex].src).then(cacanKsjbdsjd => {
        tdusfsfsdfTud.current(cacanKsjbdsjd);
    });
  }
  }, [props.currentSongIndex, props.songs, tdusfsfsdfTud]);

const rrdrrdqwwqftfSDRihwe = event => {
if (mmnnSfh.current != null) {
  if (!laslkallkllasTgi.current) {
      laslkallkllasTgi.current = zzmnmnzxmnzmxnzQde.current.createMediaElementSource(mmnnSfh.current);
      laslkallkllasTgi.current.connect(laslkallkllasQde.current);
      laslkallkllasQde.current.connect(zzmnmnzxmnzmxnzQde.current.destination);
  }  
}
};
 
async function mppqbcwewrxexWknjan(knkWWEFW) {
  const qpowqpqpwoqwpo = await fetch(knkWWEFW);
  const dsdqwqsqji = await qpowqpqpwoqwpo.blob(); 
  const cacanKsjbdsjd = URL.createObjectURL(dsdqwqsqji);
  return cacanKsjbdsjd;
}
let isPaused = false;


    useEffect(() => { //2
        if (mcnVmcn && mmnnSfh.current != null && !pauseIt) { 
            mmnnSfh.current.play(); 
        } 
      return () => {
        if(mmnnSfh.current) {
          var ewskddjlkas =  mmnnSfh.current;
          ewskddjlkas.pause();  
          // setPauseIt(true);
          // console.log("INSIDE  useEffect(() => //2 that tries to pause music.......................... pauseIt?: ",{pauseIt});
        }
      };
    });

    useEffect(() => { //3
      var ewskddjlkas =  ksjhkgakETUUIUI.current;
        if (mcnVmcn && ksjhkgakETUUIUI.current != null && !pauseIt) { 
            ksjhkgakETUUIUI.current.play();
        } 
      return () => {
        if(ewskddjlkas && ksjhkgakETUUIUI.current != null) {
            ewskddjlkas.pause();
            // setPauseIt(true);
            // console.log("inside useEffect //3 pause it? : ",{pauseIt});
        }
      };
    });
  
    useEffect(() => {
      setIsPlaying(true); 
    }, []);

    const handleSongEnd = () => { //4
      if(mcnVmcn) {
        setIsPlaying(!mcnVmcn);
        //mmnnSfh.current.pause();
        // setPauseIt(true);
        // console.log("inside handleSongEnd //4 pause it? : ",{pauseIt});
      }
      isSkipSong = true;
      Zsesrftgolkjuyut();
      setIsPlaying(mcnVmcn);
    };

    useEffect(() => {
      const audioRefNowThis = mmnnSfh.current;
      audioRefNowThis.addEventListener("ended", handleSongEnd);
      swdPnkeij(3000);
          setTimeout(() => {
            setIsPlaying(true);
          }, oiuytyui);
      return () => {
        audioRefNowThis.removeEventListener("ended", handleSongEnd);
      };
    }, [handleSongEnd, props.currentSongIndex, pauseIt]);
   
    const Zsesrftgolkjuyut = (forwards = true) => {
      props.setCurrentSongIndex((prevIndex) => {
        let newIndex = prevIndex;
        if(mcnVmcn) {
          setIsPlaying(false);
        }
        if (forwards) {
          newIndex = (newIndex + 1) % props.songs.length;
          swdPnkeij(3000);
          setTimeout(() => {
            setIsPlaying(true);
          }, oiuytyui);
        } else {
          newIndex = (newIndex - 1 + props.songs.length) % props.songs.length;
          swdPnkeij(3000); 
          setTimeout(() => {
            setIsPlaying(true);
          }, oiuytyui);
        }
        return newIndex;
      });
      isSkipSong = false;
    };

  const handlePlayRandomMusic = () => {
    if(mcnVmcn) {
      setIsPlaying(false);
    }
    

    const randomIndex = Math.floor(Math.random() * props.songs.length);
    props.setCurrentSongIndex(randomIndex);   
    swdPnkeij(3000);
    setTimeout(() => {
      setIsPlaying(true); 
    }, oiuytyui);
    
  };

  const kpkmpmkxdrdrxdxct = () => { 
    if(mmnnSfh != null && mmnnSfh.current != null) {
      mmnnSfh.current.currentTime = 0; 
    }
  };
  
  const komomxqwdqoijqwd = {
    backgroundColor: '#1DB954',
    iwyeqieyqwyeqouyuyweww: '#fff',
    padding: '10px 20px',
    borderRadius: '4px',
    fontSize: '16px',
    cursor: 'pointer',
    border: 'none',
    outline: 'none',
    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)',
  };
  
  const kokkookkuj = () => {
    window.location.href = 'https://open.spotify.com/playlist/6jpofjjyyoMuCTdiWpl3ww?si=ade38eb0013c4fae';
  };

  const handlePlay = () => {
    mmnnSfh.current.play();
    setIsPlaying(true);
  };

  const handlePause = () => { //5
    mmnnSfh.current.pause();
    // setPauseIt(true);
    // console.log("INSIDE handlePause()..........................",{pauseIt});
    setIsPlaying(false);
  };
{/* //this is Mr. Muthukumaran Azhagesan's Sdvjsdfjkasdhjhjh.js 
//Author: Mr. Muthukumaran Azhagesan, Proven Software Architect, Developer, SAP Consultant, iOS app developer, Inventor, and some more.
//check https://www.damnittameitai.co for his AI/ML projects, all interesting ones.
//check https://www.autoshields.website for Muthukumaran's useful and life saving inventions. 
//overall check https://linktr.ee/Muthukumaran.Azhagesan */}
  return (
      <div className="w-full whitespace-wrap flex-center justify-center p-3 fade-in transition-transform transform-gpu dark:bg-gray-700 dark:text-white ui-widget-content">
        <div><br/>
          <div className="nextsong-details whitespace-wrap justify-center border-collapse ui-widget-content">
            <img
              src={`${process.env.PUBLIC_URL}/songs_images/${props.songs[props.nextSongIndex].img_src}`}
              alt={props.songs[props.nextSongIndex].title}
              style={{ width: "4em", height: "auto" }}/>
            <div className="animate-blink flex flex-wrap whitespace-break-spaces dark:text-white">
              Upcoming Song:&nbsp;
              {props.songs[props.nextSongIndex].title} &nbsp; by &nbsp;
              {props.songs[props.nextSongIndex].artist}&nbsp; from album &nbsp;&nbsp;
              {props.songs[props.nextSongIndex].album}
            </div>
          </div>
        </div>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <br/>
        <button style={komomxqwdqoijqwd} onClick={kokkookkuj}>
          Click, search, and buy this & more favorite music on Spotify
        </button>
        <div className="w-11/12 music-player text-light text-light-shadow-lg font-thin dark:bg-gray-700 dark:text-white flex-center justify-center ui-widget-content">
        <audio
            src={`${process.env.PUBLIC_URL}/songs/${props.songs[props.currentSongIndex].src}`}                
            ref={mmnnSfh}
            preload="auto"
            onEnded={() => setIsPlaying(false)} 
            onPlay={rrdrrdqwwqftfSDRihwe}/>
              <div className="mb-4">       
        <div className="mb-4">      
        </div>
      </div>
        <PlayerDetails
            className="animate-fade-in to-blue-800  dark:bg-gray-700 dark:text-white"
            song={props.songs[props.currentSongIndex]}/>
         <PlayerControls
            isPlaying={ {mcnVmcn}  ? handlePause : handlePlay }
            setIsPlaying={setIsPlaying}
            SkipSong={Zsesrftgolkjuyut}/>
        <div className="flex left relative rounded-lg p-1 items-center justify-center ui-widget-content">      
        <div className="special-div canvas-container flex-direction: row">  
        <div><br/><br/><br/><br/></div>       
        <div className={`${isCanvasVisible ? 'block' : 'hidden'}`} id="canvases">
         
      <ConvexGlassCanvas 
        ref={nbzxcnzbSfh}
        glassOpacity={0.15}
        blurAmount={16}
        convexStrength={0.8}/><br/>
       <ConvexGlassCanvas 
        ref={nbzxcnzbSfh2} 
        glassOpacity={0.15}
        blurAmount={16}
        convexStrength={0.8}/><br/>
       <ConvexGlassCanvas 
        ref={nbzxcnzbSfh3} 
        glassOpacity={0.15}
        blurAmount={16}
        convexStrength={0.8}/> 
        </div>
          
          <div className="p-1">
            <div className="p-4"></div>
            {props.songs[props.currentSongIndex].display_banner ? (
            <SpectrumAnalyzer text="Visit Kumar's autoshields website {click AUTO SHIELDS link shown above to go there} to learn about his 3 useful inventions, of which 2 are life saving! All are patented and ready to go!" /> ) : (
              <div className="plain-container flex-auto">
                <p/>
              </div> 
            )}
            </div>
         </div>                             
      </div>
      <br/>
      <div className="text-xs">Digital LED Music Visualizer by Mr.Muthukumaran Azhagesan(Kumar)</div> 
      <div className="ui-widget-content">1. Select a color first</div>
      <div className="parent-div flex-direction: row ui-widget-content">          
      {options.map((option) => (        
        <div key={option.text} onClick={() => qwerTsdfg(option) }>
         <WaterDroplet>{option.text}</WaterDroplet>
        </div>
      ))}      
      </div>
      <br/>
      <div className="ui-widget-content">2. Select an fft size</div>
      <PowerSlider className="p-1.5" value={fftSize} onChange={handleFFTChange} />
          <div className="player__footer dark:bg-gray-700 dark:text-white">
            <ul className="list list--footer">
              <li>
                <a
                  id="likesCounter"
                  href="#"
                  className="list__link"
                  onClick={() => {
                    setLikes((prevLikes) => {
                      const updatedLikes = [...prevLikes];
                      updatedLikes[props.currentSongIndex]++;
                      return updatedLikes;
                    });
                  }}>
                  <i className="fa fa-heart-o"></i>
                  Likes: {likes[props.currentSongIndex]}
                </a>
              </li>
              <li>
                <a id="playRandomMusic"
                  href="#"
                  className="list__link"
                  onClick={handlePlayRandomMusic}>
                  <i className="fa fa-random"></i>
                </a>
              </li>
{/* //this is Mr. Muthukumaran Azhagesan's Sdvjsdfjkasdhjhjh.js 
//Author: Mr. Muthukumaran Azhagesan, Proven Software Architect, Developer, SAP Consultant, iOS app developer, Inventor, and some more.
//check https://www.damnittameitai.co for his AI/ML projects, all interesting ones.
//check https://www.autoshields.website for Muthukumaran's useful and life saving inventions. */}

              <li>
                <a
                  id="playThisAgain"
                  href="#"
                  className="list__link"
                  onClick={kpkmpmkxdrdrxdxct}>
                  <i className="fa fa-undo"></i>
                </a>
              </li>

              <li>
                <a href="#" className="list__link">
                  <i className="fa fa-ellipsis-h"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
  );
  
}
export default Sdvjsdfjkasdhjhjh;
//this is Mr. Muthukumaran Azhagesan's Sdvjsdfjkasdhjhjh.js 
//Author: Mr. Muthukumaran Azhagesan, Proven Software Architect, Developer, SAP Consultant, iOS app developer, Inventor, and some more.
//check https://www.damnittameitai.co for his AI/ML projects, all interesting ones.
//check https://www.autoshields.website for Muthukumaran's useful and his life saving inventions.