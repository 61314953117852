import React, {useState} from "react";
import Voice2Entertainment from "./Voice-to-entertainment";
import YouTubeNavigator from "./YouTube-Navigator";
import LotteryPrediction from "./Lottery-Prediction";
import MusicAIPioneers from "./The-AI-Music-Pioneers";
import DamnItTameItAIHistory from "./Damnit-tameit-AI-History";
import IBMWatsonAIHackathon2024Marketing from "./IBMWatsonAIHackathon2024MarketingContent";

import Team from "./Team";

function isMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "android",
    "webos",
    "iphone",
    "ipad",
    "ipod",
    "blackberry",
    "windows phone",
  ];
  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
}

const HomePage = () => {  
  const [expandedDivId, setExpandedDivId] = useState(null);
  const handleDivClick = (divId) => {
    setExpandedDivId(divId === expandedDivId ? null : divId);
  };
 // Usage
 if (isMobileDevice()) {
  // Code to handle mobile device behavior
  // console.log("Mobile device detected!");
  return (
    <div className="justify-center items-center ui-widget-content">
      <table>    
        <thead></thead>
        <tbody>
        <tr><td width="8">&nbsp;&nbsp;&nbsp;</td><td className="p-1 align-top  ui-widget-content"><IBMWatsonAIHackathon2024Marketing /></td></tr>  
        <tr><td width="8">&nbsp;&nbsp;&nbsp;</td><td className="p-1 align-top  ui-widget-content"><Voice2Entertainment /></td></tr>  
        <tr><td width="8">&nbsp;&nbsp;&nbsp;</td><td className="p-1 align-top  ui-widget-content"><YouTubeNavigator /></td></tr>
        <tr><td width="8">&nbsp;&nbsp;&nbsp;</td><td className="p-1 align-top  ui-widget-content"><LotteryPrediction /></td></tr>
        <tr><td width="8">&nbsp;&nbsp;&nbsp;</td><td className="p-1 align-top  ui-widget-content"><MusicAIPioneers /></td></tr>
        <tr><td width="8">&nbsp;&nbsp;&nbsp;</td><td className="p-1 align-top  ui-widget-content"><DamnItTameItAIHistory /></td></tr>        
        <tr><td width="8">&nbsp;&nbsp;&nbsp;</td><td className="p-1 align-top  ui-widget-content"><Team /></td></tr>
        <tr><td width="8">&nbsp;&nbsp;&nbsp;</td></tr>
        </tbody>
      </table>
    </div> );
    } else {
      // Code to handle non-mobile device behavior
      console.log("Not a mobile device.");
      return (
        <div className="ui-widget-content">
        <table className="space-x-1">
          <thead></thead>
          <tbody>
          <tr className="flex h-fit">          
            <td className="py-1 align-top h-11/12 w-auto">
              <IBMWatsonAIHackathon2024Marketing />
            </td>
          </tr> 
          <tr className="flex h-fit">          
            <td className="py-1 align-top h-11/12 w-auto">
              <Voice2Entertainment />
            </td>
          </tr>
          <tr className="flex h-fit"/>
          <tr className="flex">            
            <td className="py-1 align-top h-11/12 w-auto">
              <YouTubeNavigator />
            </td>
          </tr>
          <tr className="flex h-fit"/>
          <tr className="flex">            
            <td className="py-1 align-top h-11/12 w-full">
              <LotteryPrediction />
            </td>
          </tr>
          <tr className="flex h-fit"/>
          <tr className="flex">            
            <td className="py-1 align-top h-11/12 w-auto">
              <MusicAIPioneers />
            </td>
          </tr>
          <tr className="flex h-fit"/>
          <tr className="flex">              
            <td className="py-1 align-top h-11/12 w-auto">
              <DamnItTameItAIHistory />
            </td>
          </tr>
          <tr className="flex h-fit"/>          
          <tr className="flex h-fit">
            <td className="py-1 align-top h-11/12 w-auto">            
              <Team />
            </td>          
          </tr>
          </tbody>
        </table>
      </div> );
    }
};
export default HomePage;