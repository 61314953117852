import React, { useState, useEffect, useRef } from 'react';

const FlyingCharacters = ({ images, maxCharacters = 3, spawnDelay = 5000, maxLifetime = 10000 }) => {
  const [characters, setCharacters] = useState([]);
  const [isVisible, setIsVisible] = useState(false); // State for toggling visibility
  const lastSpawn = useRef(Date.now());

  useEffect(() => {
    let spawnInterval, animationId;
    // Only start animations if they are supposed to be visible
    if (isVisible) {
      // Function to spawn new characters at intervals
      const spawnCharacter = () => {
        if (characters.length < maxCharacters || Date.now() - lastSpawn.current > spawnDelay) {
          lastSpawn.current = Date.now();
          const newChar = {
            image: images[Math.floor(Math.random() * images.length)],
            x: Math.random() * window.innerWidth,
            y: Math.random() * (window.innerHeight - 100),
            direction: Math.random() > 0.5 ? 1 : -1,
            spawnTime: Date.now()
          };
          setCharacters(prevChars => {
            const filteredChars = prevChars.filter(char => Date.now() - char.spawnTime < maxLifetime);
            if (filteredChars.length >= maxCharacters) {
              filteredChars.pop(); // Remove the oldest character if we need to add a new one
            }
            return [...filteredChars, newChar];
          });
        }
      };

      spawnInterval = setInterval(spawnCharacter, spawnDelay);

      // Animation loop
      animationId = setInterval(() => {
        setCharacters(prevChars => 
          prevChars.map(char => {
            let newX = char.x + (2 * char.direction); // Speed of movement
            let newDirection = char.direction;
            
            // Bounce effect
            if (newX > window.innerWidth - 50) { // -50 for image width
              newX = window.innerWidth - 50;
              newDirection = -1;
            } else if (newX < 0) {
              newX = 0;
              newDirection = 1;
            }
            
            return { ...char, x: newX, direction: newDirection };
          }).filter(char => Date.now() - char.spawnTime < maxLifetime) // Remove characters that have exceeded their lifetime
        );
      }, 50);

      // Cleanup function to clear intervals when visibility is toggled off or component unmounts
      return () => {
        clearInterval(animationId);
        clearInterval(spawnInterval);
      };
    }
  }, [isVisible, images, maxCharacters, spawnDelay, maxLifetime]);

  // Toggle visibility handler
  const toggleVisibility = () => {
    setIsVisible(prevIsVisible => !prevIsVisible);
    if (!isVisible) {
      // Clear characters when hiding to restart fresh on next show
      setCharacters([]);
    }
  };

  return (
    // <div className="flex text-justify justify-center whitespace-wrap text-sm font-thin shadow-xl rounded text-red-500 p-3 mx-8 ui-widget-content">
    <div className="flex text-justify justify-center whitespace-wrap shadow-xl rounded text-red-500 p-1 ui-widget-content">
      <button onClick={toggleVisibility}>
        {isVisible ? 'Click to Hide Characters(animation is ON now)' : 'Click to show entertaining animation characters(animation is OFF now)'}
      </button>
      {isVisible && (
        <div style={{ position: 'fixed', top: 0, left: 0, pointerEvents: 'none', width: '100%', height: '100%' }}>
          {characters.map((char, index) => (
            <img 
              key={index}
              src={char.image}
              alt="Flying character"
              style={{
                position: 'absolute',
                top: char.y,
                left: char.x,
                width: '50px', // Adjust based on your image size
                height: 'auto',
                transform: `scaleX(${char.direction === 1 ? 1 : -1})` // Flip image based on direction
              }}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default FlyingCharacters;