import React, { useState } from "react";
// import voiceToEntertainmentLogo from "./images/Voice_to_Entertainment_Music-Logo.png";
import grandOrchestra from "./images/Grand-Orchestra.png";

export default function Voice2Entertainment() {
  const [collapse, setCollapse] = useState(true);  
  const [showImage, setShowImage] = useState(false);
  const handleClick = () => {
    setCollapse(!collapse);
  };
  const [expandedDivId] = useState(null);

  return (
    <div
      className="
    w-full 
    max-w-[97%] 
    mx-auto 
    h-auto 
    items-center 
    bg-white 
    dark:bg-gray-600 
    dark:text-white 
    rounded-lg 
    overflow-hidden 
    shadow-2xl 
    hover:shadow-lg 
    dark:hover:shadow-slate-50 
    ui-widget-content 
    content-center
    p-2 
    sm:p-4 
    flex 
    flex-col 
    sm:flex-row 
    space-y-2 
    sm:space-y-0 
    sm:space-x-4
    sm:items-center" onClick={handleClick}>
      <div className="justify-items-start ui-widget-content content-center align-middle"  
          onMouseEnter={() => setShowImage(true)}
          onMouseLeave={() => setShowImage(false)}>        
           {( <img 
            className="w-48 h-auto"
            src={grandOrchestra}
            alt="Music via voice"
          /> )}
          <b className="text-left text-nowrap">Voice to entertainment/<br/>Music via voice</b> 
      </div>
      <div
          className={`dark:bg-gray-600 dark:text-white border-none w-${
            expandedDivId === "div1" ? "full" : "3/4"
          }  ${collapse ? "collapsed" : ""}`}
        >     
      <div className="dark:bg-gray-600 dark:text-white">
      <div className="h-7 w-auto">&nbsp;</div>
     <div className="text-justify p-0.5 text-xs">
      <div className="mx-auto">with Whisper API(OpenAI's), Mubert Music API, with Python on huggingface.co</div>
        <br />
        <>
          <b>Objective:</b>&nbsp;To generate and make music simply based on
            voice command. You speak a command in your natural language(in any of the 100 languages), and you get the music as an output. {" "}
            <br /> <br />
            <p>
              <b>Functionalities:</b>&nbsp; User goes to my website, clicks on a
              mic button and instructs what kind of music they want. Output is
              provided in mp3 form which can be listened to for enjoyment and 
              downloaded for use.{" "}
            </p>
            <br/>
            <p>
              <b>Thanks:</b>&nbsp; To the several Python APIs that I've leveraged
              for this, and equally important lablab.ai's much friendly staff and
              the developer resources.
            </p>{" "} 
            <br/>
            <p>
              <b>Concept, Programming and Integration:</b> Muthukumaran Azhagesan,
              Kumar.ALGate@gmail.com
            </p>
            <br/>
          <b>Demo:</b>&nbsp; can be seen by <a href="https://sites.google.com/view/autoshieldsllc/a-i-machine-learning?authuser=0">clicking here</a>.
          </>
          </div>
          </div>
        </div>
      </div> 
  );
}