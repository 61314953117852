import React, { useEffect, useRef, useState } from "react";
import { BrowserRouter as Router, Route, Link, Routes } from "react-router-dom";
import "./App.css";
import Page1Hello from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";
import IBMwatsonxai2024Page from "./PageIBMwatsonxaiHackathon2024";
import HomePage from "./HomePage";
import CompanyHistory from "./Damnit-tameit-AI-History";
import Team from "./Team";
import MusicPlayer from "./musicplayer/src/MusicPlayerApp";
import SoftwareTechAndWebStack from "./SoftwareTechAndWebStack";
import SAPExperience from "./SAPExperience";
import Experience from "./SoftwareCareerExpertise";
import dtaLogo from "./images/DamnIt_TameIt_AI_Logo.png";
import liLogo from "./images/LI-Logo.png";
import linktreeLogo from "./images/Linktree-Logo.png";
import myPicture from "./images/Muthukumaran_Azhagesan-profile-picture.png"; 
import RandomClassnameDiv from './RandomCSSClass'; 
import TypingAnimation from "./musicplayer/src/components/TypingAnimation";
import LangflowHackathon from "./Langflow_July12thtoJuly14th_Hackathon";
import FalconAI71Hackathon from "./Falcon_AI71_Year_2024_Hackathon";
import IBMWatsonHackathon2024 from "./IBMWatsonHackathon2024";
import MenuItem from "./MenuItem";
import GradualTextHide from "./GradualTextHide";
import FlyingCharacters from "./components/FlyingCharacters";
import bgLights from "./images/bg_only/blue_pink.png";
const divRef = React.createRef(); 
const divToRefresh = document.getElementById('top-bar-div');


function isMobileDevice() {
  const userAgent = navigator.userAgent.toLowerCase();
  const mobileKeywords = [
    "android",
    "webos",
    "iphone",
    "ipad",
    "ipod",
    "blackberry",
    "windows phone",
  ];
  return mobileKeywords.some((keyword) => userAgent.includes(keyword));
}

const App = () => {
 
const [theme, setTheme] = useState('theme1'); // Default theme JQUeryUI
const themes = [
  { value: 'base', 		  label: 'base' },
  { value: 'black-tie', 		label: 'black-tie' },
  { value: 'blitzer', 		label: 'blitzer' },
  { value: 'cupertino', 		label: 'cupertino' },
  { value: 'dark-hive', 		label: 'dark-hive' },
  { value: 'dot-luv', 		label: 'dot-luv' },
  { value: 'eggplant', 		label: 'eggplant' },
  { value: 'excite-bike', 	label: 'excite-bike' },
  { value: 'flick', 		label: 'flick' },
  { value: 'hot-sneaks', 		label: 'hot-sneaks' },
  { value: 'humanity', 		label: 'humanity' },
  { value: 'le-frog', 		label: 'le-frog' },
  { value: 'mint-choc', 		label: 'mint-choc' },
  { value: 'overcast', 		label: 'overcast' },
  { value: 'pepper-grinder', 	label: 'pepper-grinder' },
  { value: 'redmond', 		label: 'redmond' },
  { value: 'smoothness', 		label: 'smoothness' },
  { value: 'south-street', 	label: 'south-street' },
  { value: 'start', 		label: 'start' },
  { value: 'sunny', 		label: 'sunny' },
  { value: 'swanky-purse', 	label: 'swanky-purse' },
  { value: 'trontastic', 		label: 'trontastic' },
  { value: 'ui-darkness', 	label: 'ui-darkness' },
  { value: 'ui-lightness', 	label: 'ui-lightness' },
  { value: 'vader', 		label: 'vader' }
   ];

const changeTheme = (selectedTheme) => {
    setTheme(selectedTheme);
    updateTheme(selectedTheme);
  };

const updateTheme = (selectedTheme) => { 
  const links = document.getElementsByTagName('link');
  for (let i = 0; i < links.length; i++) {
    if (links[i].getAttribute('rel') === 'stylesheet' && links[i].getAttribute('href').includes('jquery-ui.css')) {
      links[i].parentNode.removeChild(links[i]);
    }
  }
 
const link = document.createElement('link');
link.rel = 'stylesheet';
link.href = `/themes/${selectedTheme}/jquery-ui.css`;
document.head.appendChild(link);
}; 

const repaintRef = useRef(null);
const [darkMode, setDarkMode] = useState({
  value: false,
});

  //themartian
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  //themartian
  const characterImages = [
    './flying_images/Green-Apple.png',
    './flying_images/Lime.png',
    './flying_images/Pet1.png',
    './flying_images/Pet2.png',
    './flying_images/Pet3.png',
    './flying_images/Pet4.png',
    './flying_images/Pet5.png',
    './flying_images/Pet6.png',
    './flying_images/Pet7.png',
    './flying_images/Pet8.png',
    './flying_images/Pet9.png',
    './flying_images/Pet10.png',
    './flying_images/Pet11.png',
    './flying_images/Pet12.png',
    './flying_images/Pet13.png',
    './flying_images/Bird1.png',
    './flying_images/Bird2.png',
    './flying_images/Pet14.png',
    './flying_images/Pet15.png',    
    './flying_images/Pet16.png',
    './flying_images/Red-Apple.png',    
    // Add more image paths as needed
  ];

  useEffect(() => {
    if (darkMode.value) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [darkMode]);

  const toggleDarkMode = () => {
    setDarkMode({ value: !darkMode.value });
  };

  const pages = [
    { id: 1, title: "Prior Experience", link: "/Experience" },
    { id: 2, title: "Home Page", link: "/" },
    { id: 3, title: "Voice to Entertainment", link: "/page1_hello" },
    { id: 4, title: "YouTube Navigator", link: "/page2" },
    { id: 5, title: "🎵♫🎸🎹🎷Music Player 🪕🥁🎹🎸♫🎵", link: "/musicPlayer" },
    { id: 6, title: "Inventions", link: "https://www.autoshields.website/dashboard",},
    { id: 7, title: "Prediction ", link: "/page3" },
    { id: 8, title: "{iOS app} Parents' Weather Companion",
      link: "https://apps.apple.com/us/app/parental-weather-companion-2-0/id1575019437",},
    { id: 9, title: "History ", link: "/companyHistory" },
    { id: 10, title: "Team ", link: "/team" },
  ];

    const MenuItemMobile = ({ page }) => {
    const menuImage = page.labelImage ? (
      <img src={page.labelImage} style={{ resizeMode: "contain" }} alt="" />
    ) : null;
    return (
      <>     
      <tr key={page.id} className="border text-center gap-3 h-8">
        <td
          key={page.id}
          className="menubg-image-url text-gray-300 dark:bg-red-600 text-black-500 dark:text-black rounded-md cursor-pointer shadow-2xl hover:bg-blue-300  text-center"
        >
          <Link
            className=""
            key={page.id}
            to={page.link}
            target={page.id === 6 ? "_blank" : "_self"}
          >
            <div className="w-auto whitespace-nowrap text-white p-0 shadow-lg">
              {page.title} {menuImage}
            </div>
          </Link>
        </td>
      </tr>      
      </>
    );
  };
  
  const MenuItemDesktop = ({ page }) => {
    const menuImage = page.labelImage ? (
      <img src={page.labelImage} style={{ resizeMode: "contain" }} alt="" />
    ) : null;    
    return (<>
      <table><tbody><tr><td
          key={page.id}
          className="dark:bg-slate-500 text-black-500 dark:text-white rounded-md py-2 px-0.5 cursor-pointer border-blue-200 
           dark:border-green-500 shadow-md hover:bg-blue-300 dark:hover:bg-lime-300 menu"
          style={{ padding: "1px" }}>
          <Link
            key={page.id}
            to={page.link}
            target={page.id === 6 ? "_blank" : "_self"}>
            <div className="w-1/6 whitespace-nowrap text-blue-500 dark:text-white">
              {menuImage}
              <b>{page.title}</b>
            </div>
            <div className="menu-bar fixed top-0 left-0 w-full  ui-widget-content">
              <button onClick={toggleMenu}></button>
            </div>
            <div className="invisible w-3"></div>
          </Link>
        </td>
        <td>&nbsp;</td></tr></tbody>     
      </table>     
    </>
    );
  };
 
  if (isMobileDevice()) {
    // Code to handle mobile device behavior
    // console.log("Mobile device detected!");
    return (
      <Router>
        <div className="p-1 mx-1 ui-widget">
          <div className="App whitespace-wrap text-center  px-4 py-2 m-2 position: relative style={{ overflowWrap: 'break-word' }}>">
            <div className="menu-bar fixed top-0 left-0 w-full sticky-top border:none dark:shadow-gray-700 z-50">            
              <div className="shadow-2xl">              
              <RandomClassnameDiv>
                <div className="website-title flex text-3xl font-bold mb-5 z-0 custom-shadow dark:bg-slate-500 h-auto">
                <GradualTextHide/> 
                <img
                    className="p-2 w-10 h-auto object-contain flex-shrink fade-in transition-transform transform-gpu hover:scale-110 position:absolute z-99;"
                    src={dtaLogo}
                    style={{ resizeMode: "contain" }}
                    alt="damn it! tame it ai logo"
                  />  <br/>  
                  <div className="text-xs font-light"><TypingAnimation text="a website by Muthukumaran Azhagesan" /></div>
                </div>
                {/* <div className="p-0 m-0 italic align-top font-light">by Mr. Muthukumaran Azhagesan</div> */}
              </RandomClassnameDiv>
              </div>
            </div>
            <div className="h-3 w-auto">&nbsp;</div>
            <table className="flex justify-center lg:justify-start">
              <thead></thead>
              <tbody>
                <tr key="space-maker1" className="h-8 w-auto">
                  <td>&nbsp;</td>
                </tr>
                <tr key="space-maker2" className="h-12 w-auto">
                  <td>&nbsp;</td>
                </tr>
              </tbody>
            </table>
            <div className="dark:bg-slate-500">
             <div className="w-full min-h-screen dark:bg-gray-900 transition-colors duration-500">
              <div className="top-1 left-0 justify-center">
                <div className="h-1 w-auto">
                  <table className="w-3/4 whitespace-wrap table-auto mx-1 gap-0.5 centered-content">
                    <thead>            
                    </thead><tbody>      
                    <tr><td className="flex w-auto justify-center ui-widget-content"></td></tr>
                    <tr><td className="flex w-auto justify-center ui-widget-content centered-content ui-widget-content">                                  
          <label htmlFor="themeSelect" className="ui-widget-content w-auto">Pick an UI Theme for a cooler experience:&nbsp;</label>
          <select id="themeSelect" className="ui-widget-content" value={theme} onChange={(e) => changeTheme(e.target.value)}>
            {themes.map((theme) => (
              <option key={theme.value} value={theme.value}>
                {theme.label}
              </option>
            ))}
          </select>
          </td></tr> 
          <tr><td>&nbsp;</td></tr>
          <tr><td className="w-auto justify-center ui-widget-content centered-content ui-widget-content">
            <FlyingCharacters images={characterImages} maxCharacters={3} spawnDelay={5000} maxLifetime={10000} />
          </td></tr>   
          <tr><td>&nbsp;</td></tr>                    
           </tbody></table>
      <table className="w-auto justify-center ui-widget-content centered-content">
        <tbody>
          {/* <tbody className="flex text-justify justify-center whitespace-wrap text-sm font-thin shadow-xl rounded text-red-500 p-3 mx-8 ui-widget-content"> */}
          {pages.map((page) => (
            <MenuItemMobile key={page.id} page={page} />
          ))}
        </tbody>
      </table>
                </div>
              </div>
              <br />
              <div className="h-80 w-auto">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div className="h-7 w-auto">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              </div>
              <div>
              <br/><br/><br/></div>
              <br/>     
              <div className="flex text-justify justify-center whitespace-wrap text-sm font-thin shadow-sm rounded text-red-500 p-3 mx-auto ui-widget-content">              
                <div className="ui-widget-content overflow-x-auto sticky style={{ overflowWrap: 'break-word' }}">
                <div><br/><br/></div>
                  <b className="mobile-container text-blue-500 dark:text-green-300 text-center font-weight:bold">
                    NOTE: If you are on your phone or tablet or iPad, tilt to landscape mode for full view.
                  </b>
                  <br/>
                  <br/>
                  <br/>
                  
                  <b className="animate-blink">SAFETY INSTRUCTIONS:</b> music
                  player is on auto pilot. So, be cautious while driving or
                  operating any machinery, when starting music player, as the
                  audio is of high quality, and there may be music volume differences 
                  from one music track to the other. Instructions continued below.
                </div>
                
              </div><br/>
              <Routes className="items-center">
                <Route path="/" element={<HomePage />} />
                <Route path="/Experience" element={<Experience />} />
                <Route path="/IBMwatsonxai" element={<IBMwatsonxai2024Page />} />    
                <Route path="/page1_hello" element={<Page1Hello />} />
                <Route path="/page2" element={<Page2 />} />
                <Route
                  path="/musicPlayer"
                  element={<MusicPlayer />}
                  target="_self"
                />
                <Route path="/page3" element={<Page3 />} />                            
                <Route path="/companyHistory" element={<CompanyHistory />} />
                <Route path="/team" element={<Team />} />
                <Route
                  path="/JavaAndWebStack"
                  element={<SoftwareTechAndWebStack />}
                />
                <Route 
                  path="/RealSAPConsultingExperience"
                  element={<SAPExperience />}
                />
                <Route path="/Experience" element={<Experience />} />
                <Route path="/LangflowHackathon" element={<LangflowHackathon />} />
                <Route path="/FalconAI71Hackathon" element={<FalconAI71Hackathon />} />
                <Route path="/IBMWatsonXHackathon2024" element={<IBMWatsonHackathon2024 />} />
              </Routes>
            </div>
          </div>
        </div>
        {/* </div> #this just for dark mode onplay. 2023-September-29 */}
        <br></br>
        {/* Content that triggers the repaint */}
        <div ref={repaintRef}>
          &nbsp;
          {/* Content to be repainted */}
          {/* <h2>Repaint Content</h2> */}
        </div>
        <div className="flex text-justify whitespace-wrap text-sm font-thin shadow-xl rounded text-blue-500 p-3 mx-8 dark:text-white  dark:bg-slate-500 ui-widget-content">  
        <div className="w-auto whitespace-wrap table-auto mx-0.5 p-0.5">
          <b className="text-red-500 dark:text-white dark:bg-slate-500">
              Copyright Disclaimer:&nbsp;
            </b>
            The music assets made by human beings that are played on this
            website are intended for promotional purposes only. It is used to
            enhance the user experience and showcase the musical composition.
            The music property, including the composition, performance, and
            recording, is solely owned by the respective music publisher,
            artist, or copyright holder. This website does not claim any
            ownership rights or sell any products related to the music asset.
            All rights belong to their respective owners. If you are the
            copyright holder or authorized representative and would like your
            music asset to be removed from this website, please contact us at{" "}
            <a href="mailto:webmaster@damnittameitai.co">
              webmaster@damnittameitai.co
            </a>
            , and we will promptly address your request.
            <br />
            <br />
            <b className="text-red-500">safety instructions continued:&nbsp;
            &nbsp;&nbsp;</b>
            Please don't use phone/smartphone while driving. Strictly follow
            local law always, while driving. damn it tame it ai llc or the
            webmaster and/or any associated parties are not responsible in any
            way, <br />
            or form, for any damage(s) or loss due to using Smartphone or this
            website features.
          </div>
        </div>
        <span className="text-gray-500"></span> 
        <div className="flex text-justify whitespace-wrap text-sm font-thin shadow-xl rounded text-blue-500 p-3 mx-8  ui-widget-content">  
        <div className="w-11/12 whitespace-wrap table-auto mx-auto  p-0.5 bg-slate-700 dark:text-white  ui-widget-content">
            In addition to regular software consulting in any industry, Damn
            it tame it AI is founded to tame AI ML and DL based models,
            ensuring their outcomes are under our(humans') control at all
            times/any time(now and in the future), with certainty.
            Mr.Muthukumaran Azhagesan
        </div> 
        </div>
        <footer className="bg-blue-900 text-white py-4 p-3 overflow-x-auto sticky">
          <div className="container mx-auto flex flex-col items-center">
            {/* <p className="text-sm w-full p-3 whitespace-wrap text-justify"> */}
            <p className="w-11/12 whitespace-wrap table-auto mx-auto p-0.5 text-justify text-sm font-thin ui-widget-content">
              contact : For business inquiries and for Enterprise Java/Spring
              Boot/Microservices, Cloud, Webstack, SAP, or iOS: all proven
              consulting that includes enterprise architecture, design,
              development, fast prototyping, enhancements & for thorough
              enterprise security assessments plus remediations, and/or AI &
              Machine Learning System implementations(to stay ahead of
              competitors)<br></br>
            </p>
            <p className="text-sm">USA phone: +1 832.655.9594</p>
            <p className="text-sm">
              <a href="https://www.linkedin.com/in/mazhagesan">
                <img
                  className="h-auto md:rounded-none mx-auto shadow-xl fade-in transition-transform transform-gpu scale-50 hover:scale-75 object-position: center;  w-screen"
                  src={liLogo}
                  alt="Linkedin Logo"
                />
              </a>
            </p>
            <p className="text-sm">
              <a href="https://linktr.ee/Muthukumaran.Azhagesan">
                <img
                  className="h-auto md:rounded-none mx-auto shadow-xl fade-in transition-transform transform-gpu scale-50 hover:scale-75 object-position: center;  w-screen"
                  src={linktreeLogo}
                  alt="Linktree Logo"
                />
              </a>
            </p>

            <p className="text-sm">
                  <a href="https://www.linkedin.com/in/mazhagesan">
                    <img
                      className="w-45 h-auto md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu scale-100  hover:scale-125 object-position: center;"
                      src={myPicture}
                      style={{ resizeMode: "contain" }}
                      alt="Muthukumaran Azhagesan"
                    />
                  </a>
                </p>
            <p className="text-sm">
              ©2023 to present Damn it tame it AI. All rights reserved.
            </p>
            <p>
              <a href="mailto:kumar.algate@gmail.com">Kumar.ALGate@gmail.com</a>
            </p>
          </div>
        </footer>
        </div>
        <FlyingCharacters images={characterImages} maxCharacters={3} spawnDelay={5000} maxLifetime={10000} />
      </Router>
    );
  } else {
    // console.log("Not a mobile device.");      
    return (
      <Router>
        <div className="p-4 mx-auto container ui-widget">
          <div className="App flex flex-col w-full whitespace-wrap text-center px-4 py-2 m-2 scrollable-div overflow-y:auto p-4 mx-auto content ui-widget-content">
            <div className="menu-bar fixed top-0 left-0 w-full sticky-top border:none dark:shadow-gray-300 z-50">
              <div className="shadow-2xl">
              <RandomClassnameDiv>
                <h1 ref={divRef} className="website-title flex text-3xl font-bold mb-0.5 z-0 drop-shadow-xl bg-text-green-500">
                  &nbsp;&nbsp;&nbsp;<GradualTextHide/>&nbsp;&nbsp;&nbsp;            
                <img
                    className="p-2 w-10 h-auto object-contain align-super flex-shrink fade-in transition-transform transform-gpu shadow-2xl hover:scale-110 position:absolute z-99;"
                    src={dtaLogo}
                    style={{ resizeMode: "contain" }}
                    alt="damn it! tame it ai logo"
                  /></h1>
                  <div className="text-sm"><TypingAnimation text="a website by Muthukumaran Azhagesan" /></div>
              </RandomClassnameDiv>
              </div> 
            </div>
            <table>
              <thead></thead>
              <tbody>
                <tr key="space-maker1" className="h-14 w-auto">
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div className="min-h-screen dark:bg-gray-900 transition-colors duration-500 centered-content">
              <header>
              <table className="ui-widget-content w-full"><thead></thead><tbody>
                {/* <tr><td><img
                    className="p-0 w-auto h-auto object-contain align-super fade-in transition-transform transform-gpu position:absolute z-99;"
                    src={bgLights}
                    style={{ resizeMode: "contain" }}
                    alt="bg lights"
                  /></td></tr> */}
            <tr><td>
              <label htmlFor="themeSelect"><b>Pick an UI Theme for a cooler experience:</b>&nbsp;</label>
              <select id="themeSelect" value={theme}  className="ui-widget-content"onChange={(e) => changeTheme(e.target.value)}>
                {themes.map((theme) => (
                  <option key={theme.value} value={theme.value} className="ui-widget-content">
                    {theme.label}
                  </option>
                ))}
              </select>
              </td>
              </tr></tbody>
          </table>          
              </header>
              <br/>
              <table className="w-full whitespace-wrap sticky-top glass-effect dark:shadow-gray-300 z-50 text-xs">
      <thead>
      </thead>
      <tbody>
        <tr key="gap-maker" className="gap-0.5 p-0.5 sticky w-fit ui-widget content-center">
          {pages.map((page) => (
            <td 
              key={page.id}
              className="ui-widget-content transition-transform duration-200 hover:scale-111 hover:z-10"
            >
              <MenuItemDesktop
                key={page.id}
                page={page}
                className="ui-widget-content w-full text-sm content ui-widget-content font-bold hover:text-blue-600 ui-widget-content  dark:hover:text-blue-400 content-center"
              />
            </td>
          ))}
        </tr>
      </tbody>
    </table>
           
              {/* Content that triggers the repaint */}
              <div ref={repaintRef}>
                {/* Content to be repainted */}
                <h2>&nbsp;</h2>
              </div>
              <div className="col-span-8 text-red-400 dark:text-green-300 shadow-sm w-full whitespace-wrap overflow-x-auto sticky ui-widget-content">
                <b className="animate-blink">SAFETY INSTRUCTIONS:</b> music
                player is on autopilot. So, be cautious when starting music player, while driving or operating any
                machinery,  as the audio is high quality. <br />
                Instructions continued below.
              </div>    <br/>          
              <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/page1_hello" className="bg-slate-100 apple-glossy" element={<Page1Hello />} />
                <Route path="/page2" className="bg-slate-100 apple-glossy" element={<Page2 />} />
                <Route path="/musicPlayer" className="apple-glossy" element={<MusicPlayer />} target="_self"/>
                <Route path="/page3" className="bg-slate-100 apple-glossy" element={<Page3 />} />
                <Route path="/IBMwatsonxai" className="bg-slate-100 apple-glossy" element={<IBMwatsonxai2024Page />} />    
                <Route path="/companyHistory" className="bg-slate-100 apple-glossy" element={<CompanyHistory />} />
                <Route path="/team" className="bg-slate-100 apple-glossy" element={<Team />} />
                <Route
                  path="/JavaAndWebStack" className="bg-slate-100 apple-glossy" 
                  element={<SoftwareTechAndWebStack />}
                />
                <Route
                  path="/RealSAPConsultingExperience" className="bg-slate-100 apple-glossy" 
                  element={<SAPExperience />}
                />
                <Route path="/Experience" className="bg-slate-100 apple-glossy" element={<Experience />} />                
                <Route path="/LangflowHackathon" element={<LangflowHackathon />} />
                <Route path="/FalconAI71Hackathon" element={<FalconAI71Hackathon />} />
                <Route path="/IBMWatsonXHackathon2024" element={<IBMWatsonHackathon2024 />} />
              </Routes>
            </div>
            <br></br>
            <div className="w-5/6 raised-content dark:bg-gray-900 font-light flex flex-wrap shadow-xl rounded max-w-full mb-8 overflow-x-auto p-4 mx-auto ui-widget-content">
              <div className="dark:text-white text-justify  px-36 py-2 m-2">
                <b className="text-red-500 dark:text-white">
                  Copyright Disclaimer:
                </b>
                The music assets made by human beings that are played on this
                website are intended for promotional purposes only. It is used
                to enhance the user experience and showcase the musical
                composition. The music property, including the composition,
                performance, and recording, is solely owned by the respective
                music publisher, artist, and/or copyright holder. This website
                does not claim any ownership rights or sell any products related
                to the music asset(s). All rights belong to their respective
                owners. If you are the copyright holder or authorized
                representative and would like your music asset to be removed
                from this website, please contact us at{" "}
                <a href="mailto:webmaster@damnittameitai.co">
                  webmaster@damnittameitai.co
                </a>
                , and we will promptly address your request.
                <br />
                <br />
                <b className="text-red-500">
                  safety instructions continued:&nbsp;
                </b>
                <div className="text-red-500 dark:text-green-300">
                  Please don't use phone/smartphone while driving. Strictly
                  follow local law always, while driving. damn it tame it ai llc
                  or the webmaster and/or any associated parties are not
                  responsible in any way, or form, for any damage(s) or loss due
                  to using Smartphone or this website features.
                </div>
              </div>
            </div>
            <span className="text-gray-500"></span>
            <table className="mb-8 w-full whitespace-wrap ui-widget-content">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="raised-content text-light text-light-shadow-lg font-bold  dark:bg-gray-900 dark:text-white  w-screen text-justify  px-36 py-2 m-2  ui-widget-content">
                    In addition to regular software consulting in any industry,
                    Damn it tame it AI is founded To tame AI ML and DL based
                    models,
                    <br />
                    ensuring their outcomes are under our(humans') control at
                    all times/any time(now and in the future), with certainty.
                    <br />
                    Mr. Muthukumaran Azhagesan
                  </td>
                </tr>
                <tr>
                  <td className="raised-content text-light text-light-shadow-lg font-bold  dark:bg-gray-900 dark:text-white w-screen   ui-widget-content">
                    <a href="https://www.linkedin.com/in/mazhagesan">
                      <img
                        className="h-auto md:rounded-none shadow-xl fade-in transition-transform transform-gpu scale-50 hover:scale-75 object-position: center; overflow-x-auto sticky"
                        src={liLogo}
                        alt="Linkedin Logo"
                      />
                    </a>
                  </td>
                </tr>
                <tr></tr>
                <tr>
                  <td className="raised-content text-light text-light-shadow-lg font-bold  dark:bg-gray-900 dark:text-white w-screen   ui-widget-content">
                    <a href="https://linktr.ee/Muthukumaran.Azhagesan">
                      <img
                        className="h-auto md:rounded-none shadow-xl fade-in transition-transform transform-gpu scale-50 hover:scale-75 object-position: center; overflow-x-auto sticky"
                        src={linktreeLogo}
                        alt="Linktree Logo"
                      />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
            <footer className="w-auto raised-content text-light text-light-shadow-lg font-bold  dark:bg-gray-900 dark:text-white text-justify  px-36 py-2 m-2 ui-widget-content">
                <p>
                  contact: For business inquiries and for Enterprise Java/Spring
                  Boot/Microservices, Cloud, Webstack, SAP, or iOS: all proven
                  consulting that includes enterprise architecture, design,
                  development, fast prototyping, enhancements & for thorough
                  enterprise security assessments plus remediations, and/or AI &
                  Machine Learning System implementations(to stay ahead of
                  competitors)<br></br>
                </p>
                <p className="text-sm">USA phone: +1 832*655*9594</p>
                <p className="text-sm">
                  <a href="https://www.linkedin.com/in/mazhagesan">
                    <img
                      className="w-45 h-auto md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu scale-100  hover:scale-125 object-position: center;"
                      src={myPicture}
                      style={{ resizeMode: "contain" }}
                      alt="Muthukumaran Azhagesan"
                    />
                  </a>
                </p>
                <p className="text-sm">
                  ©2023 to present. Damn it tame it AI. All rights reserved.
                </p>
                <p>
                  <a href="mailto:kumar.algate@gmail.com">
                    Kumar.ALGate@gmail.com
                  </a>
                </p>
            </footer>
          </div>
        </div>
      </Router>
    );
  }
};
export default App;