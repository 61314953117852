import React, { useState, useEffect } from "react";
import PlayerForMyResumes from "./musicplayer/src/components/PlayerForMyResumes.js";
import "./musicplayer/src/css/Player.css";
import Bitbucket from "./images/java-tech-stack/Bitbucket.png";
import Bitcoin from "./images/java-tech-stack/Bitcoin.png";
import eth from "./images/java-tech-stack/eth.png";
import blockchain from "./images/java-tech-stack/blockchain.png";
import CFF_Logo_rgb from "./images/java-tech-stack/CFF_Logo_rgb.png";
import AWS_Logo from "./images/java-tech-stack/AWS_Logo.png";
import AWS_Services from "./images/java-tech-stack/AWS-services.png";
import DockerLogo from "./images/java-tech-stack/DockerLogo.png";
import GitHub from "./images/java-tech-stack/GitHub.png";
import GitLab from "./images/java-tech-stack/GitLab.png";
import GlassFishLogo from "./images/java-tech-stack/GlassFish-Logo.png";
import Grafana_icon from "./images/java-tech-stack/Grafana_icon.png";
import hibernateLogo from "./images/java-tech-stack/hibernate-logo.png";
import HTML5 from "./images/java-tech-stack/HTML5.png";
import CSS3Logo from "./images/java-tech-stack/CSS3-Logo.png";
import nodejsLogo from "./images/java-tech-stack/nodejs-1-logo-png-transparent.png";
import IBMAIXLogo from "./images/java-tech-stack/IBM-AIX.png";
import Java5StarBadge from "./images/java-tech-stack/Java-5-Star-Badge.png";
import JavaLogo from "./images/java-tech-stack/Java-Logo.png";
import encryption from "./images/java-tech-stack/encryption.png";
import JavaScriptLogo from "./images/java-tech-stack/JavaScript-Logo.png";
import JBoss from "./images/java-tech-stack/JBoss.png";
import jettyLogo from "./images/java-tech-stack/jetty-logo.jpg";
import apacheTomcatLogo from "./images/java-tech-stack/Apache-Tomcat-Logo.png";
import jQueryUI from "./images/java-tech-stack/jQueryUI.png";
import JSONLogo from "./images/java-tech-stack/JSON-Logo.png";
import Kibana from "./images/java-tech-stack/Kibana.png";
import log4j from "./images/java-tech-stack/log4j.png";
import slf4j from "./images/java-tech-stack/slf4j.jpg";
import kubernetes from "./images/java-tech-stack/kubernetes.png";
import LinuxPenguinLogo from "./images/java-tech-stack/Linux-Penguin-Logo.png";
import MavenLogo from "./images/java-tech-stack/Maven-Logo.png";
import JenkinsLogo from "./images/java-tech-stack/JenkinsLogo.png";
import VercelLogo from "./images/java-tech-stack/VercelLogo.png";
import MicrosoftLogo from "./images/java-tech-stack/Microsoft-Logo.png";
import OpenAI from "./images/java-tech-stack/OpenAI.png";
import OracleDatabaseLogo from "./images/java-tech-stack/Oracle-Database-Logo.jpg";
import SQLServerDatabaseLogo from "./images/java-tech-stack/SQL-Server-Data-Tools-SQL-Server-Logo.png";
import PostgreSQL from "./images/java-tech-stack/PostgreSQL.jpg";
import mongoDB from "./images/java-tech-stack/mongoDB.png";
import ProjectManagement from "./images/java-tech-stack/Project-Management.png";
import pythonLogo from "./images/java-tech-stack/python-logo.png";
import kafka from "./images/java-tech-stack/kafka.png";
import RabbitMQ from "./images/java-tech-stack/RabbitMQ.png";
import reactjsLogo from "./images/java-tech-stack/reactjs-logo.png";
import angularLogo from "./images/java-tech-stack/angular-logo.png";
import RStudio from "./images/java-tech-stack/RStudio.png";
import SAPFioriforiOS from "./images/java-tech-stack/SAP-Fiori-for-iOS.png";
import SAPLeonardoBlockchain from "./images/java-tech-stack/SAP-Leonardo-Blockchain.png";
import SAPLogo from "./images/java-tech-stack/SAP-Logo.png";
import SOAP from "./images/java-tech-stack/SOAPUI.png";
import ReSTAPI from "./images/java-tech-stack/REST-API.png";
import springBoot from "./images/java-tech-stack/spring-boot.png";
import springTools from "./images/java-tech-stack/spring-tools.png";
import eclipseLogo from "./images/java-tech-stack/eclipse-logo.png";
import oracleFusionMiddlewareLogo from "./images/java-tech-stack/Oracle-Fusion-Middleware.png";
import SwaggerLogo from "./images/java-tech-stack/SwaggerLogo.png";
import Unity from "./images/java-tech-stack/Unity.png";
import Vertex from "./images/java-tech-stack/Vertex.jpg";
import VSCodeLogo from "./images/java-tech-stack/VS-Code-Logo.png";
import WildFlyLogo from "./images/java-tech-stack/WildFly-Logo.jfif";
import RedHatOpenShiftLogo from "./images/java-tech-stack/redhat-openshift-container-logo.jpg";
import Xcode from "./images/java-tech-stack/Xcode.png";
import companies from "./images/Fortune-companies-I-worked-for.png";

import cert1 	from "./images/certs/Hackerrank Java Certificate.png";
import cert2   from "./images/certs/AI Startup OpenAI Whisper GPT3 CHATGPT COHERE Codex DALL-E-2 Hackathon - 2023-Feb17 to Feb24 - 5AZIMC49JR13.png";
import cert3   from "./images/certs/Build with AI 2020 Certificate.png";
import cert4   from "./images/certs/Cohere AI Hackathon - 2022-Nov18 to Nov21 - XZUXXJTRSFNU.png";
import cert5   from "./images/certs/Cohere-AI-Hackathon#3-2022-Sep30-to-Oct02-616HFMH2AFSI.png";
import cert6   from "./images/certs/Data Scientist Toolbox.png";
import cert7   from "./images/certs/Blockchain Basics SUNY Coursera.png";
import cert8   from "./images/certs/SAP Leonardo - An Intro to Blockchain.png";
import cert9   from "./images/certs/OpenAI Whisper GPT3 Codex Hackathon - 2022-Dec09 to Dec17 - XWFBA4YVNG5W.png";
import cert10  from "./images/Parental Weather Companion Analytics.png";
import cert11  from "./images/certs/An Intro to SAP HANA.png";
import cert12  from "./images/certs/Next Steps in SAP HANA Cloud Platform.png";
import cert13  from "./images/certs/SAP Fiori for iOS - Build Your First Native Mobile App.png";
import cert14  from "./images/certs/HTML-Programmer-Certificate001.jpg";
import cert15  from "./images/certs/JavaScript-Programmer-Certificate001.jpg";
import cert16  from "./images/certs/Web-Programmer-Certificate001.jpg";
import softwares  from "./images/SAP/SAP-experience-keywords-with-watermarks.png";
import AI_MLExperience from "./images/AI&ML/My-AI-&-ML-expertise.png";

export default function SAPExperience() {
  const [isTracking] = useState(false);
  const handleTrackingChange = () => {
    isTracking = !isTracking;
  };
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-97RZLFSXZ6';        
    script.async = true;
    document.head.appendChild(script);
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag('js', new Date());
    gtag('config', 'G-97RZLFSXZ6');
  }, []);

  const [currentSongIndex, setCurrentSongIndex] = useState(0);
  const [nextSongIndex, setNextSongIndex] = useState(currentSongIndex + 1);
  const [songs] = useState([
  {
    title: "Music score generated with AI ML DL",
    artist: "Google Music LM and Muthukumaran Azhagesan",
    album: "Generative AI Music",
    track: "AI_Test_Kitchen_upbeat_music_fantastic2",
    year: "2023",
    img_src: "./songs_images/Picture_from_Mars6.png",
    src: "./songs/AI_Test_Kitchen_upbeat_music_fantastic2.mp3",
  },  
{
    title: "Music score generated with AI ML DL",
    artist: "Google Music LM and Muthukumaran Azhagesan",
    album: "Generative AI Music",
    track: "AI_Test_Kitchen_upbeat_music_fantastic3",
    year: "2023",
    img_src: "./songs_images/Picture_from_Mars7.png",
    src: "./songs/AI_Test_Kitchen_upbeat_music_fantastic3.mp3",
  },  
{
    title: "Music score generated with AI ML DL",
    artist: "Google Music LM and Muthukumaran Azhagesan",
    album: "Generative AI Music",
    track: "AI_Test_Kitchen_upbeat_music_fantastic4",
    year: "2023",
    img_src: "./songs_images/Picture_from_Mars8.png",
    src: "./songs/AI_Test_Kitchen_music_in_ilaiyaraja_style__an_action_movie_opening_scene.mp3",
  },  
{
    title: "Music score generated with AI ML DL",
    artist: "Google Music LM and Muthukumaran Azhagesan",
    album: "Generative AI Music",
    track: "AI_Test_Kitchen_upbeat_music_fantastic5",
    year: "2023",
    img_src: "./songs_images/Planet_Adjacent_to_Mars1.png",
    src: "./songs/AI_Test_Kitchen_upbeat_music_fantastic5.mp3",
  },  
{
    title: "Music score generated with AI ML DL",
    artist: "Google Music LM and Muthukumaran Azhagesan",
    album: "Generative AI Music",
    track: "AI_Test_Kitchen_upbeat_music_fantastic6",
    year: "2023",
    img_src: "./songs_images/Planet_Adjacent_to_Mars2.png",
    src: "./songs/AI_Test_Kitchen_upbeat_music_fantastic6.mp3",
  }  
      ]);

    useEffect(() => {
      setNextSongIndex(() => {
        if (currentSongIndex + 1 > songs.length - 1) {
          return 0;
        } else {
          return currentSongIndex + 1;
        }
      });
    }, [currentSongIndex, songs.length]);

  return (
    <div className="text w-full mx-1 position: relative z--1 shadow-xl bg-white  dark:bg-gray-600 dark:text-white ui-widget-content">
        <div className="h-4 w-auto">&nbsp;</div>
        <h2 className="font-bold text-5xl justify-center flex flex-wrap w-full">
          Muthukumaran Azhagesan's SAP Consulting Experience
        </h2>
        <br />        
        <div className="flex flex-wrap text-lg font-thin shadow-xl rounded max-w-full mb-4 overflow-x-visible p-4 w-90% whitespace-wrap text-justify justify-center items-center align-items-center">
        <img className="p-1 h-auto w-auto object-contain music-player space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={companies} alt="Software skillset" />          
          <h3>
            Mr. (Kumar)Muthukumaran Azhagesan is a <b>proven Software Professional</b>. As an SAP Consultant(ex-SAP employee), he has worked for and proved his excellent architecture design and development skills by working for
            Global Fortune Companies(#1, and similar ranks) such as SAP, Exxon-Mobil, IBM, Citibank, Bank of Ireland, Fannie Mae,
            AmerisourceBergen, Siemens, Philips, Lowe's, (hp)Hewlett-Packard, Cisco, only to name a top few. Kumar has proven SAP implementation experience in areas such as but not limited to SAP SD/CRM/SAP CRM Web UI/SAP IPC, SAP Fiori, SAP ecommerce websites(b2b, b2c), and 
            currently specializes in the specifics, that follows.
          </h3>
        </div>
        {/* <PlayerForMyResumes
        currentSongIndex={currentSongIndex}
        setCurrentSongIndex={setCurrentSongIndex}
        nextSongIndex={nextSongIndex}
        songs={songs}
      />         */}
      <br />
      {/* <DocumentDownloadForm /> */}
      
      <div className="flex flex-col align-items-center">
      <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={softwares} alt="SAP-expertise" />
      <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert11} alt="An Intro to SAP HANA.png" />
        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert12} alt="Next Steps in SAP HANA Cloud Platform.png" />
        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert13} alt="SAP Fiori for iOS - Build Your First Native Mobile App.png" />
        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert8}  alt="SAP Leonardo - An Intro to Blockchain.png" />

        <img className="p-1 h-auto w-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert1} alt="Hackerrank Java Certificate" />
        <img className="p-1 h-auto w-1/3 object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert14} alt="HTML Programmer Certificate" />        
        <img className="p-1 h-auto w-1/3 object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert15} alt="JavaScript Programmer Certificate" />        
        <img className="p-1 h-auto w-1/3 object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert16} alt="Web Programmer Certificate" />
        

        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert2}  alt="AI Startup OpenAI Whisper GPT3 CHATGPT COHERE Codex DALL-E-2 Hackathon - 2023-Feb17 to Feb24 - 5AZIMC49JR13.png" />
        
        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert3}  alt="Build with AI 2020 Certificate.png" />
                
        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert6}  alt="Data Scientist Toolbox.png" />
        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert7}  alt="Blockchain Basics SUNY Coursera.png" />
        
        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert9}  alt="OpenAI Whisper GPT3 Codex Hackathon - 2022-Dec09 to Dec17 - XWFBA4YVNG5W.png" />
        <img className="p-1 h-auto object-contain space-y-1 md:rounded-none  mx-auto shadow-xl fade-in transition-transform transform-gpu hover:h-125 object-position: center;" src={cert10} alt="Parental Weather Companion Analytics.png" />
      </div>




      <div className="flex flex-wrap justify-center gap-5 grids grid-flow-col-dense">  
      <img
          className="p-1 w-50 h-50 object-contain flex-shrink  shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={SAPLogo}
          alt="SAPLogo"
        />
        <img
          className="p-1 w-45 h-45 object-contain flex-shrink  shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={SAPFioriforiOS}
          alt="SAPFioriforiOS"
        />
        <img
          className="p-1 w-45 h-45 object-contain  flex-shrink  shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={SAPLeonardoBlockchain}
          alt="SAPLeonardoBlockchain"
        />        
        <img
          className="p-1 w-36 h-36 object-contain  flex-shrink  shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={Xcode}
          alt="Xcode"
        />
        <img
          className="p-1 w-36 h-36 object-contain  flex-shrink  shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={Vertex}
          alt="Vertex"
        />
        <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
          SAP Integration with Vertex
        </span>
      
        <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP Fiori UI/UX
        </span>
          
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP Fiori UI/UX based iOS Apps
          </span>
          <span className="w-auto h-50 ui-widget-content    border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP ABAP Stack, RFCs, FMs, BAPI, BADI
          </span>
          {/* <span className="w-auto h-50 ui-widget-content    border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP ICM (Incentive and Commission Management), Compensation plan
        </span> */}
          <span className="w-auto h-50 ui-widget-content    border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP SSC/CPQ
          </span>
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP VC(Variant Config), pricing procedures, condition tables, pricing conditions, (B)bill (O)of (M)materials, etc.
          </span>
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP IPC(Pricing and Config)
          </span>
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP JCo/JRA - (Java connector adapter)
        </span>
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP Java Stack
         </span><div className="h-6 w-auto"> </div>
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP SD/CRM
          </span>
          <span className="w-auto h-50 ui-widget-content border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP IDOC, EDI
          </span>
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP S/4 HANA (ECC)
          </span>   
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP BTP
          </span>        
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            Microservices
          </span>                 
          <span className="w-auto h-50 ui-widget-content  border-spacing-2 md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center; bg-white  dark:bg-gray-600 dark:text-white;">
            SAP NetWeaver Development Infrastructure<br/>(NWDI)
          </span>       
        </div>
        <div className="h-6 w-auto"> </div> 
        <div className="w-full flex flex-wrap justify-center sm:w-2/3 md:w-1/2 lg:w-1/3 xl:w-1/4 mx-auto p-1  grid grid-cols-5 border-collapse">  
         </div>          
        <div className="flex flex-wrap justify-center grid grid-cols-7 gap-2 shrink p-3"> 
<>
        <a href="https://www.hackerrank.com/muthukumaran1?hr_r=1">
          <img
            className="w-32 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
            src={Java5StarBadge}
            alt="Java5StarBadge"
          />
        </a>
          <img
            className="w-45 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
            src={JavaLogo}
            alt="JavaLogo"
          />
          <img
            className="w-52 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
            src={springBoot}
            alt="springBoot"
          />
          <img
            className="w-45 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
            src={springTools}
            alt="springTools"
          />
          <img
            className="w-32 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
            src={eclipseLogo}
            alt="eclipseLogo"
          />
          <img
            className="p-1 w-32 h-32 object-contain shrink  shadow-xl  mx-auto fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
            src={oracleFusionMiddlewareLogo}
            alt="Oracle Fusion Middleware Logo"
          />
          <span className="p-1 w-32 h-25 md:rounded-none shadow-xl  mx-auto fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;">
            Spring Security
          </span>
          <span className="p-1 w-32 h-25  md:rounded-none   mx-auto    shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;">
            Spring Data
          </span>
          <div className="w-35 h-35  md:rounded-none  mx-auto     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;">
          <img
            className="p-1 w-25 h-25 md:rounded-none  mx-auto   shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
            src={encryption}
            alt="encryption and decryption"
          />
            <h3>Encryption and Decryption</h3>
          </div>
          <img
            className="p-1 w-auto h-35  md:rounded-none     shadow-xl fade-in text-3xl transition-transform transform-gpu scale-75 hover:scale-125 object-position: right-1;"
            src={hibernateLogo}
            alt="hibernateLogo"
          />
</>
 
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={OracleDatabaseLogo}
          alt="OracleDatabaseLogo"
        />
        <img
          className="w-48 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={SQLServerDatabaseLogo}
          alt="SQLServerDatabaseLogo"
        />
        <img
          className="bg-white w-32 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={PostgreSQL}
          alt="PostgreSQL"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={mongoDB}
          alt="mongoDB"
        />
        <img
          className="w-32 h-auto md:rounded-none  object-contain shrink mx-auto    shadow-xl fade-in  transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={JSONLogo}
          alt="JSONLogo"
        />
        <img
          className="w-32 h-auto md:w-32 md:h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={kafka}
          alt="kafka"
        />
        <img
          className="w-32 h-auto md:w-32 md:h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={RabbitMQ}
          alt="RabbitMQ"
        />
        <img
          className="w-32 h-auto md:w-32 md:h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={SOAP}
          alt="SOAP"
        />
        <img
          className="w-32 h-auto md:w-32 md:h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={ReSTAPI}
          alt="REST API"
        />
        <img
          className="w-32 h-auto md:w-32 md:h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={SwaggerLogo}
          alt="SwaggerLogo"
        />
         <img
          className="w-32 h-32 md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={HTML5}
          alt="HTML5"
        />
        <img
          className="w-32 h-32  mx-auto  shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={JavaScriptLogo}
          alt="JavaScriptLogo"
        />
        <img
          className="w-32 h-32  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={CSS3Logo}
          alt="CSS3Logo"
        />       
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={jQueryUI}
          alt="jQueryUI"
        />
        <img
          className="w-45 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={reactjsLogo}
          alt="reactjsLogo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={angularLogo}
          alt="angularLogo"
        />
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={nodejsLogo}
          alt="nodejsLogo"
        />
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={VercelLogo}
          alt="VercelLogo"
        />
        <img
          className="w-45 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={CFF_Logo_rgb}
          alt="CFF_Logo_rgb"
        />     
         <img
          className="w-35 h-auto md:rounded-none  mx-auto    shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 gap-3 object-position: center;"
          src={AWS_Logo}
          alt="AWS_Logo"
        />
        <img
          className="w-auto h-auto md:rounded-none mx-auto shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-175 gap-3 object-position: center;"
          src={AWS_Services}
          alt="AWS Services"
        /> 
        <img
          className="w-44 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 p-3 object-position: center;"
          src={MavenLogo}
          alt="MavenLogo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={JenkinsLogo}
          alt="JenkinsLogo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={JBoss}
          alt="JBoss"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={WildFlyLogo}
          alt="WildFlyLogo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={apacheTomcatLogo}
          alt="apacheTomcatLogo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={jettyLogo}
          alt="jettyLogo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={GlassFishLogo}
          alt="GlassFishLogo"
        />
        <img
          className="w-45 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={VSCodeLogo}
          alt="VSCodeLogo"
        />
        <img
          className="w-45 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={GitHub}
          alt="GitHub"
        />
        <img
          className="w-40 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={GitLab}
          alt="GitLab"
        />
        <img
          className="w-40 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={Bitbucket}
          alt="Bitbucket"
        />
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={Bitcoin}
          alt="Bitcoin"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={eth}
          alt="ETH"
        />
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={blockchain}
          alt="blockchain"
        />
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={Kibana}
          alt="Kibana"
        />
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={slf4j}
          alt="slf4j"
        />
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={log4j}
          alt="log4j"
        />
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={Grafana_icon}
          alt="Grafana_icon"
        />       
        <img
          className="w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={DockerLogo}
          alt="DockerLogo"
        />
        <img
          className="w-40 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={kubernetes}
          alt="kubernetes"
        />
        <img
          className="w-45 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={MicrosoftLogo}
          alt="MicrosoftLogo"
        />
        <img
          className="p-3 w-32 h-auto md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={OpenAI}
          alt="OpenAI"
        />
        <img
          className="w-45 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={IBMAIXLogo}
          alt="IBMAIXLogo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={LinuxPenguinLogo}
          alt="LinuxPenguinLogo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={RedHatOpenShiftLogo}
          alt="RedHat OpenShift Logo"
        />
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={ProjectManagement}
          alt="ProjectManagement"
        />
      <a href="https://www.hackerrank.com/muthukumaran1?hr_r=1">
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={pythonLogo}
          alt="pythonlogo"
        />
      </a>
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={RStudio}
          alt="RStudio"
        />        
        <img
          className="w-32 h-auto  md:rounded-none  mx-auto     shadow-xl fade-in transition-transform transform-gpu scale-75 hover:scale-125 object-position: center;"
          src={Unity}
          alt="Unity"
        />
      </div>  
      <br />
      <div className="flex flex-wrap justify-center grid-cols-6 gap-2 flex-shrink">
        <img
            className="w-3/4 h-3/4 md:rounded-none  mx-auto  shadow-xl fade-in transition-transform transform-gpu scale-100  hover:scale-125 object-position: center;" 
            src={AI_MLExperience}
            alt="AI_MLExperience"
          />
      </div>  
      <br/>
      <div className="flex flex-wrap justify-center ">
          <h3>
          plus more SAP modules, and many more latest software tools and technologies in the SAP world. Project experience in Waterfall, Agile, Scrum, Lean, (XP)eXtreme Programming, RAD, and other popular methodologies. 
          Kumar(Mr.Muthukumaran Azhagesan) has 15+ years proven U.S. Professional Experience.
          </h3>
        </div>
        <hr></hr>
        <hr></hr>
        <hr></hr>
      <p className="text-sm">
        Important Note: Each Software, Product, and/or Logo mentioned herein
        each are trademarks or registered trademarks and property of the
        respective Organization or Company, Worldwide.
      </p>
    </div>
  );
}
